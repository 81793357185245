import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const container = document.querySelector(
      ".tradingview-widget-container__widget"
    );
    if (!container.hasChildNodes()) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
      script.async = true;
      script.innerHTML = JSON.stringify({
        symbols: [
          { proName: "FOREXCOM:SPXUSD", title: "SP500" },
          { proName: "KUCOIN:SOLUSDT", title: "SOL" },
          { proName: "BITSTAMP:EURUSD", title: "EURUSD" },
          { proName: "KUCOIN:BTCUSDT", title: "BTC" },
          { proName: "KUCOIN:ETHUSDT", title: "ETH" },
          { proName: "KUCOIN:XRPUSDT", title: "XRP" },
          { proName: "KUCOIN:ADAUSDT", title: "ADA" },
          { proName: "KUCOIN:DOTUSDT", title: "DOT" },
          { proName: "KUCOIN:DOGEUSDT", title: "DOGE" },
          { proName: "KUCOIN:BNBUSDT", title: "BNB" },
          { proName: "KUCOIN:LTCUSDT", title: "LTC" },
          { proName: "KUCOIN:LINKUSDT", title: "LINK" },
          { proName: "KUCOIN:UNIUSDT", title: "UNI" },
          { proName: "KUCOIN:XLMUSDT", title: "XLM" },
          { proName: "KUCOIN:XTZUSDT", title: "XTZ" },
          { proName: "KUCOIN:TRXUSDT", title: "TRX" },
        ],
        showSymbolLogo: true,
        isTransparent: true,
        displayMode: "regular",
        colorTheme: "dark",
        locale: "es",
      });

      container.appendChild(script);
    }
  }, []);

  return (
    <header className="bg-panelBackground text-white w-full shadow-md">
      {/* Barra superior con logo, navegación y usuario */}
      <div className="flex items-center justify-between px-4">
        {/* Logo */}
        <div className="flex items-center">
          <img
            src="/assets/logo.png"
            alt="Loopin Logo"
            className="absolute mt-4 left-2 h-16 w-auto sm:h-16 md:h-20 lg:h-17 object-contain translate-y-2"
          />
        </div>
        {/* Menú de navegación */}
        <nav className=" flex flex-grow justify-center space-x-1 sm:space-x-6 py-2 text-xs sm:text-sm md:text-base font-semibold">
          {[
            { path: "/", label: "Dashboard" },
            { path: "/market", label: "Market" },
            { path: "/operations", label: "Operations" },
            { path: "/history", label: "History" },
          ].map((item) => (
            <Link
              key={item.path}
              to={item.path}
              className={`relative px-2 sm:px-4 sm:py-1 rounded-md shadown-md hover:bg-gray-700 transition-colors duration-300 ${
                location.pathname === item.path
                  ? "text-[#43be95] hover:text-[#43be95]"
                  : "hover:text-[#43be95]"
              }`}
            >
              {item.label}
              {/* Hover slash para la pestaña activa */}
              {location.pathname === item.path && (
                <span className="absolute inset-x-0 -bottom-1 h-[2px] bg-[#43be95]"></span>
              )}
            </Link>
          ))}
        </nav>

        {/* Información del usuario */}
        <div className="flex-shrink-0 flex items-center space-x-2 sm:space-x-4">
          {user ? (
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="focus:outline-none"
                aria-label="User Menu"
              >
                <img
                  src={user.picture || <FaUserCircle />}
                  alt="User Avatar"
                  className="h-8 w-8 mt-2 sm:h-10 sm:w-10 rounded-full border border-gray-400 hover:border-accent "
                />
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 w-48 bg-panelBackground shadow-lg rounded-md py-2">
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 text-sm hover:bg-gray-700 hover:text-white"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          ) : (
            <Link
              to="/login"
              className="text-xs sm:text-sm hover:text-gray-400"
            >
              Login
            </Link>
          )}
        </div>
      </div>

      {/* TradingView Widget */}
      <div className="tradingview-widget-container W-full">
        <div className="tradingview-widget-container__widget"></div>
      </div>
    </header>
  );
};

export default Header;
