import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import Dashboard from './components/Dashboard';
import Market from './components/Market'; 
import Operations from './components/Operations';
import History from './components/History';
import Login from './components/Login';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import './styles/global.css';
import './styles/output.css';
import Layout from "./components/Layout";

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                    {/* Ruta pública: login */}
                    <Route path="/login" element={<Login />} />

                    {/* Rutas protegidas */}
                    <Route
                    path="/*"
                    element={
                        <PrivateRoute>
                        <Layout>
                            <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/market" element={<Market />} />
                            <Route path="/operations" element={<Operations />} />
                            <Route path="/history" element={<History />} />
                            </Routes>
                        </Layout>
                        </PrivateRoute>
                    }
                    />

                </Routes>
            </Router>
        </AuthProvider>
    );
};

export default App;
