import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import { useAuth } from "../hooks/useAuth";
import { FaInfoCircle } from "react-icons/fa";
import {
  fetchDashboardData,
  fetchFuturesAccountDetails,
  fetchPositionsHistory,
  calculateTotalProfit,
  calculatePercentagePNL,
  calculateOperationsByAlias,
  calculateDailyOperationsByAlias,
  calculateLargestProfit,
  calculateLargestLoss,
  calculateDailyPNL,
  calculateWinningRate,
  calculateAverageOperationsPerDay,
} from "../services/api";

const Dashboard = () => {
  const { user } = useAuth();
  const [showPopup, setShowPopup] = useState(false);
  const [dashboardData, setDashboardData] = useState([]);
  const [futuresAccountDetails, setFuturesAccountDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profitHistory, setProfitHistory] = useState([]);
  const [dailyPNL, setDailyPNL] = useState([]);
  const [history, setHistory] = useState([]);
  const [accountEquityHistory, setAccountEquityHistory] = useState([]); // Historial de accountEquity
  const [totalAccountEquity, setTotalAccountEquity] = useState([0]); // Suma de accountEquity

  const loadData = async () => {
    try {
      if (user?.id) {
        setLoading(true);
  
        // Carga de datos desde diferentes endpoints
        const [dashboard, futuresData, historyData] = await Promise.all([
          fetchDashboardData(user.id),
          fetchFuturesAccountDetails(user.id), // Ahora devuelve un array
          fetchPositionsHistory(user.id),
        ]);
  
        // Configuración de estados
        setDashboardData(dashboard);
        setFuturesAccountDetails(Array.isArray(futuresData) ? futuresData : []);
        setHistory(historyData);
  
        // Procesamiento de datos calculados
        const pnlData = calculateDailyPNL(historyData);
        setDailyPNL(pnlData);
  
        const profitHistoryFromHistory = historyData.map((item) => ({
          date: new Date(item.closeTime).toLocaleDateString(),
          profit: parseFloat(item.pnl || 0),
        }));
        setProfitHistory(profitHistoryFromHistory);
  
        // Calcular el total de `accountEquity`
        const totalEquity = (Array.isArray(futuresData) ? futuresData : []).reduce(
          (sum, account) => sum + (account.accountEquity || 0),
          0
        );
        setTotalAccountEquity(totalEquity);

        setAccountEquityHistory((prevHistory) => [
          ...prevHistory,
          {
            date: new Date().toLocaleString(),
            accountEquity: totalEquity,
          },
        ]);
      }
    } catch (err) {
      console.error("Error loading data:", err);
    } finally {
      setLoading(false);
    }
  };
  
  // Efecto para cargar datos al iniciar y cada 30 segundos
  useEffect(() => {
    loadData();
    const intervalId = setInterval(() => loadData(), 30000); // Cada 30 segundos
    return () => clearInterval(intervalId);
  }, [user?.id]);

  // Calcula el saldo total disponible sumando todas las cuentas
  const totalAvailableBalance = futuresAccountDetails.reduce(
    (sum, account) => sum + (account.availableBalance || 0),
    0
  );

  
  const totalPNLPercentage = calculatePercentagePNL(history);
  const aliasCounts = calculateOperationsByAlias(history);
  const dailyAliasCounts = calculateDailyOperationsByAlias(history);
  const largestProfit = calculateLargestProfit(history);
  const largestLoss = calculateLargestLoss(history);
  const latestDailyPNL =
    dailyPNL.length > 0 ? dailyPNL[dailyPNL.length - 1].pnl : 0;

  const getTextColor = (value) => {
    if (value > 0) return "text-green-500";
    if (value < 0) return "text-red-500";
    return "text-white";
  };



  // Preparar datos para el gráfico
  const pnlData = history.map((item) => ({
    date: new Date(item.closeTime).toLocaleDateString(), // Fecha formateada
    pnl: parseFloat(item.pnl || 0), // PNL (convertido a número)
    alias: item.alias || "Sin Alias", // Alias
    symbol: item.symbol || "Sin Símbolo", // Símbolo
  }));

  return (
    <div
      className="mt-2 flex flex-col h-screen gap-8"
      style={{ height: "85vh", overflowY: "auto" }}
    >
      {/* Tarjetas y gráficos responsivos */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Tarjetas */}
        <div className="grid grid-cols-3 sm:grid-cols-3 gap-4">
          {[
            {
              title: "USDT's Disponible",
              value: `${Number(totalAccountEquity).toFixed(2)} $`, // Convertido explícitamente a número
              color: "text-green-400",
            },
            {
              title: "Ganancias Totales",
              value: `${calculateTotalProfit(history)} $`,
              color: getTextColor(calculateTotalProfit(history)),
            },
            {
              title: "PNL Total",
              value: `${totalPNLPercentage}%`,
              color: getTextColor(totalPNLPercentage),
            },
            {
              title: "Mayor Ganancia",
              value: `${largestProfit} $`,
              color: getTextColor(largestProfit),
            },
            {
              title: "Mayor Pérdida",
              value: `${largestLoss} $`,
              color: getTextColor(largestLoss),
            },
            {
              title: "PNL Diario",
              value: `${latestDailyPNL} $`,
              color: getTextColor(latestDailyPNL),
            },
            {
              title: "Tasa de Operaciones Ganadoras",
              value: `${calculateWinningRate(history)}%`,
              color: getTextColor(calculateWinningRate(history)),
            },
            {
              title: "Promedio Operaciones al Día",
              value: `${calculateAverageOperationsPerDay(history)}`,
              color: getTextColor(calculateAverageOperationsPerDay(history)),
            },
            {
              title: "Operaciones Totales por Bot",
              value: (
                <div>
                  {Object.entries(aliasCounts).map(([alias, count]) => (
                    <div key={alias} className="text-lg md:text-xl font-medium">
                      {alias}: <span className="text-green-500">{count}</span>
                    </div>
                  ))}
                </div>
              ),
              color: "text-green-500",
            },
            ].map((card, index) => (
              <div
                key={index}
                className="bg-panelBackground bg-opacity-95 p-4 rounded-lg shadow-md flex flex-col justify-center items-center relative" // Asegúrate de que sea "relative"
              >
                <h3 className="text-base md:text-lg font-medium text-white text-center">
                  {card.title}
                </h3>
                <div
                  className={`text-xl md:text-2xl lg:text-3xl font-bold ${card.color}`}
                >
                  {card.value}
                </div>
                {/* Ícono de información */}
                {card.title === "Operaciones Totales por Bot" && (
                  <button
                    onClick={() => setShowPopup(true)}
                    className="absolute top-2 right-2 text-white hover:text-blue-400 focus:outline-none"
                    aria-label="Info"
                  >
                    <FaInfoCircle size={20} />
                  </button>
                )}
              </div>
            ))}
        </div>

        {/* Popup */}
        {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-panelBackground p-4 md:p-6 rounded-lg shadow-lg w-[90%] max-w-lg relative">
              <button
                onClick={() => setShowPopup(false)}
                className="absolute top-2 right-2 text-red-500 hover:text-red-700 focus:outline-none"
                aria-label="Close"
              >
                ✖️
              </button>
              <h3 className="text-lg font-semibold mb-4 text-center">
                Operaciones Diarias por Bot
              </h3>
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-400">
                  <thead className="text-xs text-gray-300 uppercase bg-gray-700">
                    <tr>
                      <th className="py-2 px-2 md:px-4">Fecha</th>
                      <th className="py-2 px-2 md:px-4">Alias</th>
                      <th className="py-2 px-2 md:px-4">Operaciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(dailyAliasCounts).map(([date, aliases]) =>
                      Object.entries(aliases).map(([alias, count]) => (
                        <tr
                          key={`${date}-${alias}`}
                          className="border-b border-gray-700"
                        >
                          <td className="py-2 px-2 md:px-4">{date}</td>
                          <td className="py-2 px-2 md:px-4">{alias}</td>
                          <td className="py-2 px-2 md:px-4 text-green-500">
                            {count}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {/* Gráficos */}
        <div className="flex flex-col gap-4">
          <div className="bg-panelBackground bg-opacity-95 p-6 rounded-xl shadow-lg">
          <ResponsiveContainer width="100%" height={300}>
  <AreaChart
    data={pnlData}
    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
  >
    {/* Gradiente verde para el área */}
    <defs>
      <linearGradient id="areaGradient" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#10B981" stopOpacity={0.8} /> {/* Verde */}
        <stop offset="100%" stopColor="#10B981" stopOpacity={0} />
      </linearGradient>
    </defs>

    {/* Grid */}
    <CartesianGrid strokeDasharray="3 3" stroke="#4B5563" opacity={0.5} />

    {/* Ejes */}
    <XAxis
      dataKey="date"
      tick={{ fill: "#D1D5DB", fontSize: 12 }}
      tickLine={false}
      axisLine={{ stroke: "#4B5563" }}
    />
    <YAxis
      tick={{ fill: "#D1D5DB", fontSize: 12 }}
      tickLine={false}
      axisLine={{ stroke: "#4B5563" }}
      tickFormatter={(value) => `${value.toFixed(2)} USDT`}
    />

    {/* Tooltip */}
    <Tooltip
      contentStyle={{
        backgroundColor: "rgba(31, 41, 55, 0.9)",
        borderRadius: "10px",
        border: "none",
        color: "#FFFFFF",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
      }}
      labelStyle={{ color: "#10B981", fontWeight: "bold" }}
      formatter={(value, name, props) => [
        `${value.toFixed(2)} USDT`,
        `Alias: ${props.payload.alias || "N/A"}, Símbolo: ${props.payload.symbol || "N/A"}`,
      ]}
      labelFormatter={(label) => `Fecha: ${label}`}
    />

    {/* Área con gradiente verde */}
    <Area
      type="monotone"
      dataKey="pnl"
      stroke="#10B981"
      strokeWidth={2}
      fillOpacity={1}
      fill="url(#areaGradient)"
      isAnimationActive={true}
    />
  </AreaChart>
</ResponsiveContainer>





          </div>

          <div className="bg-panelBackground bg-opacity-95 p-6 rounded-xl shadow-lg">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={Object.entries(dailyAliasCounts).map(
                  ([date, aliases]) => ({
                    date,
                    operations: Object.values(aliases).reduce(
                      (a, b) => a + b,
                      0
                    ),
                  })
                )}
                margin={{ top: 20, right: 20, left: 10, bottom: 20 }}
              >
                {/* Degradado moderno para la línea */}
                <defs>
                  <linearGradient id="lineGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#9333ea" stopOpacity={0.9} />
                    <stop offset="100%" stopColor="#4c1d95" stopOpacity={0.6} />
                  </linearGradient>
                </defs>

                {/* Grid y ejes modernos */}
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke="#2d3748"
                  opacity={0.5}
                />
                <XAxis
                  dataKey="date"
                  tick={{ fill: "#d1d5db", fontSize: 12 }}
                  tickLine={false}
                  axisLine={{ stroke: "#4b5563" }}
                />
                <YAxis
                  tick={{ fill: "#d1d5db", fontSize: 12 }}
                  tickLine={false}
                  axisLine={{ stroke: "#4b5563" }}
                />
                <Tooltip
                  cursor={{ stroke: "rgba(75, 85, 99, 0.5)", strokeWidth: 2 }}
                  contentStyle={{
                    backgroundColor: "rgba(31, 41, 55, 0.9)",
                    borderRadius: "10px",
                    border: "none",
                    color: "#ffffff",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                  }}
                  labelStyle={{ color: "#a78bfa", fontWeight: "bold" }}
                  formatter={(value) => [
                    `${value} operaciones`,
                    "Número de Operaciones",
                  ]}
                />
                <Line
                  type="monotone"
                  dataKey="operations"
                  stroke="url(#lineGradient)"
                  strokeWidth={3}
                  dot={{ r: 4, fill: "#9333ea" }} // Puntos en cada dato
                  activeDot={{ r: 6, stroke: "#4c1d95", strokeWidth: 2 }} // Punto resaltado al pasar el cursor
                  isAnimationActive={true} // Activar animación
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
