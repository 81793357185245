import React, { useState, useRef, useEffect,} from 'react';
import { openOrder, closeOrder, saveUserConfig, saveOrderConfig, fetchApiConfigs, deleteApiConfig, toggleBotEnabled, fetchOrderConfigs} from '../services/api';
import { useAuth } from '../hooks/useAuth';
import { FaUserCog, FaCogs, FaKey, FaRobot, FaTrash, FaPowerOff, FaCheck, FaTimes, FaPlus, FaCopy } from 'react-icons/fa';

const Operations = () => {
    const { user } = useAuth();
    const widgetContainer = useRef(null);

    const [activeTab, setActiveTab] = useState("Loopin Trader");
    // Procesa los símbolos al inicializar
    const [symbols] = useState([
        "SUIUSDTM",
        "SOLUSDTM",
        "XRPUSDTM",
        "XLMUSDTM",
        "BTCUSDTM",
        "ETHUSDTM",
        "TRXUSDTM",
        "ADAUSDTM",
        "BNBUSDTM",
        "DOTUSDTM",
        "HBARUSDTM",
        "DOGEUSDTM",
        "XTZUSDTM",
        "KSMUSDTM",
        "ICPUSDTM",
        "ETCUSDTM",
        "VETUSDTM",
        "CHZUSDTM",
        "STMXUSDTM",
        "LTCUSDTM",
        "LINKUSDTM",
        "UNIUSDTM",
        "MATICUSDTM",
        "AAVEUSDTM",
        "SUSHIUSDTM",
        "COMPUSDTM",
        "MKRUSDTM",
        "YFIUSDTM",
        "AVAXUSDTM",
        "ALGOUSDTM",
    ].map(symbol => ({
        fullSymbol: symbol, // Guarda el símbolo completo
        displayName: symbol.replace("USDTM", "") // Solo muestra la moneda
    })));
    
    const [symbol, setSymbol] = useState("SUIUSDTM");
    const [leverage, setLeverage] = useState(5);
    const [quantity, setQuantity] = useState(95);
    const [stopLossPercent, setStopLossPercent] = useState(20);
    const [takeProfitPercent, setTakeProfitPercent] = useState(0.3);
    const [isUserConfigOpen, setIsUserConfigOpen] = useState(false);
    const [isOpsConfigOpen, setIsOpsConfigOpen] = useState(false);
    const [autoCloseEnabled, setAutoCloseEnabled] = useState(false);
    const [riskMarginMaxPercent, setRiskMarginMaxPercent] = useState(1);
    const [riskMarginMinPercent, setRiskMarginMinPercent] = useState(1);
    const [riskMarginHoursBack, setRiskMarginHoursBack] = useState(12);
    const [alias, setAlias] = useState("");
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [apiPassphrase, setApiPassphrase] = useState("");
    const [botEnabled, setBotEnabled] = useState(true);
    const [apiConfigs, setApiConfigs] = useState([]);
    const [selectedApiId, setSelectedApiId] = useState(null);
    const [deleteApiId, setDeleteApiId] = useState(null);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Nuevo estado para el panel lateral
    const [isFormPopupOpen, setIsFormPopupOpen] = useState(false); // Estado para el popup del formulario




    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopyToClipboard = () => {
        if (user?.id) {
            navigator.clipboard.writeText(user.id)
                .then(() => {
                    setCopySuccess(true);
                    setTimeout(() => setCopySuccess(false), 2000); // Resetea el mensaje tras 2 segundos
                })
                .catch((err) => console.error("Error al copiar al portapapeles:", err));
        }
    };


    useEffect(() => {
        if (user) {
            fetchOrderConfigs(user.id)
                .then((configs) => {
                    console.log("Configuraciones recibidas:", configs);
                    if (configs) {
                        setRiskMarginMinPercent(configs.riskMarginMinPercent || 1);
                        setRiskMarginMaxPercent(configs.riskMarginMaxPercent || 1);
                        setRiskMarginHoursBack(configs.riskMarginHoursBack || 12); 
                        setAutoCloseEnabled(configs.autoCloseEnabled || false); 
                        setSymbol(configs.symbol || "SUIUSDTM"); 
                        setLeverage(configs.leverage || 5); 
                        setQuantity(configs.balanceUsage || 95); 
                        setStopLossPercent(configs.stopLossPercent || 20); 
                        setTakeProfitPercent(configs.takeProfitPercent || 0.3); 
                    }
                })
                .catch((error) => {
                    console.error("Error al obtener configuraciones:", error);
                });
        }
    }, [user]); // Se ejecuta cuando cambia el `user`
    
    
    useEffect(() => {
        let intervalId;
    
        const fetchConfigs = () => {
            fetchApiConfigs(user.id)
                .then((configs) => {
                    console.log("Configuraciones actualizadas:", configs);
                    setApiConfigs(Array.isArray(configs) ? configs : []);
                })
                .catch((error) => {
                    console.error("Error al actualizar configuraciones:", error);
                });
        };
    
        if (isSidebarOpen && user) {
            // Actualiza inmediatamente cuando el panel se abre
            fetchConfigs();
    
            // Inicia el intervalo para actualizaciones periódicas
            intervalId = setInterval(() => {
                fetchConfigs();
            }, 10000); // Intervalo de 30 segundos
        }
    
        return () => {
            // Limpia el intervalo cuando el panel se cierre o el componente se desmonte
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isSidebarOpen, user]); // Dependencias: actualiza si cambia el estado del panel o el usuario
    


    useEffect(() => {
        if (widgetContainer.current) {
            const script = document.createElement("script");
            script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
            script.type = "text/javascript";
            script.async = true;
            script.innerHTML = `{
              "autosize": true,
              "symbol": "KUCOIN:SUIUSDT",
              "interval": "1",
              "timezone": "Europe/Madrid",
              "theme": "dark",
              "style": "1",
              "locale": "en",
              "withdateranges": true,
              "range": "1D",
              "hide_side_toolbar": false,
              "allow_symbol_change": true,
              "calendar": false,
              "withdateranges": true,
              "details": true,
              "hotlist": true,
              "show_popup_button": true,
              "popup_width": "1000",
              "popup_height": "650",
              "watchlist": [
                "KUCOIN:SOLUSDT",
                "KUCOIN:SUIUSDT",
                "KUCOIN:BTCUSDT",
                "KUCOIN:XLMUSDT",
                "KUCOIN:XRPUSDT",
                "KUCOIN:BNBUSDT",
                "KUCOIN:ADAUSDT",
                "KUCOIN:TRXUSDT",
                "KUCOIN:DOTUSDT",
                "KUCOIN:HBARUSDT"
              ],
              "details": true,
              "hotlist": true,
              "calendar": false,
              "studies": [
              ],
              "show_popup_button": true,
              "popup_width": "1000",
              "popup_height": "650"
            }`;
            widgetContainer.current.innerHTML = ""; // Limpia el contenedor
            widgetContainer.current.appendChild(script);
        }
    }, []);

    const handleToggleBotEnabled = async (apiId) => {
        try {
            if (!apiId) {
                console.error("API ID no válido.");
                return;
            }
    
            // Encuentra la configuración actual
            const selectedConfig = apiConfigs.find((config) => config.apiId === apiId);
            if (!selectedConfig) return;
    
            // Invierte el estado de `botEnabled`
            const newState = !selectedConfig.botEnabled;
    
            // Llama a la API para cambiar el estado
            const updatedConfig = await toggleBotEnabled(apiId, newState);
    
            // Actualiza el estado local con el resultado de la API
            setApiConfigs((prevConfigs) =>
                prevConfigs.map((config) =>
                    config.apiId === apiId ? { ...config, botEnabled: updatedConfig.botEnabled } : config
                )
            );
    
            //alert(`Estado del bot actualizado a: ${newState ? "Activado" : "Desactivado"}`);
        } catch (error) {
            console.error("Error al cambiar el estado del bot:", error.response?.data || error.message);
            alert("Error al cambiar el estado del bot.");
        }
    };
    
    
    
    

    const handleTabClick = (tab) => setActiveTab(tab);

    const handleSaveUserConfig = async () => {
        const configData = {
            userId: user.id,
            alias,
            apiKey,
            apiSecret,
            apiPassphrase,
            botEnabled,
        };
    
        try {
            await saveUserConfig(configData); // Guarda la configuración en el servidor
            alert("Configuración guardada correctamente.");
    
            // Realiza un fetch completo de las configuraciones
            const updatedConfigs = await fetchApiConfigs(user.id);
            setApiConfigs(updatedConfigs);
    
            // Encuentra la nueva configuración y selecciónala automáticamente
            const newConfig = updatedConfigs.find((c) => c.alias === configData.alias);
            if (newConfig) {
                setSelectedApiId(newConfig.apiId);
                handleSelectApiConfig(newConfig.apiId); // Refleja los datos en el formulario
            }
        } catch (error) {
            console.error("Error al guardar la configuración de usuario:", error);
            alert("Hubo un error al guardar la configuración.");
        }
    };
    
    
    

    const handleSaveOrderConfig = async () => {
        const orderData = {
            userId: user.id,
            leverage: leverage,
            balanceUsage: quantity,
            symbol: symbol,
            currency: "USDT",
            stopLossPercent: stopLossPercent,
            takeProfitPercent: takeProfitPercent,
            autoCloseEnabled: autoCloseEnabled,
            riskMarginMaxPercent: riskMarginMaxPercent,
            riskMarginMinPercent: riskMarginMinPercent,
            riskMarginHoursBack: riskMarginHoursBack
        };

        try {
            await saveOrderConfig(orderData);
            alert("Configuración de usuario guardada correctamente.");
        } catch (error) {
            console.error("Error al guardar la configuración de usuario:", error);
            alert("Hubo un error al guardar la configuración.");
        }
    };

    const handleSaveOpsConfig = () => {
        alert("Configuración de operaciones guardada correctamente.");
        setIsOpsConfigOpen(false);
    };

    const handleBuy = async () => {
        const orderData = {
            signal: "LONG",
            symbol: symbol,
            currency: "USDT",
            priceType: activeTab === "Loopin Trader" ? "limitPrice" : undefined,
            leverage: leverage,
            balanceUsage: quantity,
            stopLossPercent: stopLossPercent,
            takeProfitPercent: takeProfitPercent,
        };

        try {
            const response = await openOrder(orderData, user);
            alert(`Orden de compra abierta: ${JSON.stringify(response)}`);
        } catch (error) {
            console.error("Error al abrir la orden de compra:", error);
        }
    };

    const handleSell = async () => {
        const orderData = {
            signal: "SHORT",
            symbol: symbol,
            currency: "USDT",
            priceType: activeTab === "Loopin Trader" ? "limitPrice" : undefined,
            leverage: leverage,
            balanceUsage: quantity,
            stopLossPercent: stopLossPercent,
            takeProfitPercent: takeProfitPercent,
        };

        try {
            const response = await openOrder(orderData, user);
            alert(`Orden de venta abierta: ${JSON.stringify(response)}`);
        } catch (error) {
            console.error("Error al abrir la orden de venta:", error);
        }
    };

    const handleCloseOrder = async () => {
        try {
            const response = await closeOrder(user);
            alert(`Orden de venta abierta: ${JSON.stringify(response)}`);
        } catch (error) {
            console.error("Error al abrir la orden de venta:", error);
        }
    };

    const handleSelectApiConfig = (apiId) => {
        setSelectedApiId(apiId);
        const selectedConfig = apiConfigs.find((config) => config.apiId === apiId);
        if (selectedConfig) {
            setAlias(selectedConfig.alias || "");
            setApiKey(selectedConfig.apiKey || "");
            setApiSecret(selectedConfig.apiSecret || "");
            setApiPassphrase(selectedConfig.apiPassphrase || "");
            setBotEnabled(selectedConfig.botEnabled || false);
        }
    };

    const openDeleteConfirmation = (apiId) => {
        setDeleteApiId(apiId);
        setIsDeleteConfirmOpen(true);
    };
    

    const handleDeleteApiConfig = async () => {
        try {
            await deleteApiConfig(deleteApiId);
            const updatedConfigs = apiConfigs.filter((config) => config.apiId !== deleteApiId);
            setApiConfigs(updatedConfigs);
            setIsDeleteConfirmOpen(false);
            //alert("Configuración eliminada correctamente.");
        } catch (error) {
            console.error("Error al eliminar la configuración:", error);
            alert("Error al eliminar la configuración.");
        }
    };
    
    
    

    return (
      <div className="flex flex-wrap lg:flex-nowrap gap-6 h-full w-full  py-2 mt-2 rounded-lg">
        {/* Gráfico de TradingView */}
        <div className="flex-grow rounded-lg bg-gray-900 shadow-lg h-[400px] sm:h-[500px] md:h-[600px] lg:h-[700px] w-full">
          <div
            ref={widgetContainer}
            style={{ height: "100%", width: "100%" }}
            className="rounded-lg"
          ></div>
        </div>

        {/* Formulario de Operaciones */}
        <div
          style={{ height: "100%", width: "30%" }}
          className="flex-grow bg-panelBackground rounded-lg p-4 shadow-lg h-[400px] sm:h-[500px] md:h-[600px] lg:h-[700px] w-full max-w-full"
        >
          <div className="space-y-4">
            <label htmlFor="symbol" className="text-sm font-medium"></label>
            <select
              id="symbol"
              value={symbol}
              onChange={(e) => setSymbol(e.target.value)}
              className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
            >
              {symbols.map(({ fullSymbol, displayName }) => (
                <option key={fullSymbol} value={fullSymbol}>
                  {displayName}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-between border-b border-gray-600 pb-2">
            {["Loopin Trader", "Loopin Auto Bot", "Stop límite"].map((tab) => (
              <button
                key={tab}
                className={`tab-button px-4 py-2 text-sm ${
                  activeTab === tab
                    ? "text-[#43be95] border-b-2 border-[#43be95] font-bold"
                    : "hover:text-[#43be95]"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </button>
            ))}
          </div>

          <div className="operations-form mt-4">
            <div>
              <div className="form-group flex items-center gap-2">
                <label className="text-sm font-medium">Apalancamiento:</label>
                <input
                  type="range"
                  min="1"
                  max="75"
                  value={leverage}
                  onChange={(e) => setLeverage(e.target.value)}
                  className="flex-1 accent-[#43be95]"
                />
                <span className="text-sm font-medium">{leverage}x</span>
              </div>
              <div className="form-group flex items-center gap-2 mt-4">
                <label className="text-sm font-medium">Cantidad:</label>
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={quantity}
                  onChange={(e) => setQuantity(e.target.value)}
                  className="flex-1 accent-[#43be95]"
                />
                <span className="text-sm font-medium">{quantity}</span>
              </div>
            </div>
          </div>

          <div className="form-group flex justify-between gap-2 mt-4">
            <div className="w-1/2">
              <label className="text-sm font-medium">Stop Loss (%):</label>
              <input
                type="number"
                className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                value={stopLossPercent}
                onChange={(e) => setStopLossPercent(e.target.value)}
              />
            </div>
            <div className="w-1/2">
              <label className="text-sm font-medium">Take Profit (%):</label>
              <input
                type="number"
                className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                value={takeProfitPercent}
                onChange={(e) => setTakeProfitPercent(e.target.value)}
              />
            </div>
          </div>

          {activeTab === "Loopin Trader" && (
            <div className="flex flex-grow justify-between gap-2 mt-4">
              <button
                className="bg-[#43be95] text-white px-2 py-1 sm:px-4 sm:py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                onClick={handleBuy}
              >
                Comprar/Largo
              </button>
              <button
                className="bg-[#3b340d] text-white px-2 py-1 sm:px-4 sm:py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                onClick={handleCloseOrder}
              >
                Cerrar
              </button>
              <button
                className="bg-danger text-white px-2 py-1 sm:px-4 sm:py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                onClick={handleSell}
              >
                Vender/Corto
              </button>
            </div>
          )}

          {activeTab === "Loopin Auto Bot" && (
            <div>
              <div className="w-1/2">
                <label className="text-sm font-medium">
                  Risk margin Hours Back:
                </label>
                <input
                  type="number"
                  className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                  value={riskMarginHoursBack}
                  onChange={(e) => setRiskMarginHoursBack(e.target.value)}
                />
              </div>
              <div className="form-group flex justify-between gap-2 mt-4">
                <div className="w-1/2">
                  <label className="text-sm font-medium">
                    Risk margin MAX (%):
                  </label>
                  <input
                    type="number"
                    className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                    value={riskMarginMaxPercent}
                    onChange={(e) => setRiskMarginMaxPercent(e.target.value)}
                  />
                </div>
                <div className="w-1/2">
                  <label className="text-sm font-medium">
                    Risk margin MIN (%):
                  </label>
                  <input
                    type="number"
                    className="w-full mt-1 p-2 bg-secondary rounded-lg border border-gray-700 text-textLight focus:border-[#43be95]"
                    value={riskMarginMinPercent}
                    onChange={(e) => setRiskMarginMinPercent(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group flex items-center justify-center mt-4">
                <div className="flex w-6 h-6">
                  <input
                    type="checkbox"
                    id="autoCloseEnabled"
                    checked={autoCloseEnabled}
                    onChange={(e) => setAutoCloseEnabled(e.target.checked)}
                    className="appearance-none w-full h-full border-2 border-gray-700 rounded-full bg-dark focus:outline-none focus:ring-2 focus:ring-[#43be95] checked:bg-[#43be95] transition-all cursor-pointer shadow-md"
                  />
                </div>
                <label
                  htmlFor="autocloseEnabled"
                  className="text-sm font-medium gap-6 ml-2 text-center"
                >
                  Activar cierre automático
                </label>
              </div>
              <div className="flex justify-between gap-1 mt-4">
                <button
                  className="bg-[#34362b] text-white px-4 py-2 rounded-md hover:opacity-90 transition-opacity flex-1"
                  onClick={handleSaveOrderConfig}
                >
                  Guardar Parámetros
                </button>
              </div>
            </div>
          )}

          {/* Botones Configuración */}
          <div className="secondary-buttons flex justify-around mt-4">
            <button
              className="bg-panelBackground px-4 py-2 rounded-lg text-white hover:bg-gray-700 text-sm"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            >
              <FaUserCog size={20} />
            </button>
            <button
              className="bg-panelBackground px-4 py-2 rounded-lg text-white hover:bg-gray-700 text-sm"
              onClick={() => setIsOpsConfigOpen(true)}
            >
              <FaCogs size={20} />
            </button>
          </div>
        </div>

        {/* Panel lateral */}
        <div
          className={`fixed left-0 h-full bg-gray-800 shadow-md transform  transition-transform duration-300 $⇽ ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } w-full md:w-96 lg:w-[350px]`}
        >
          {/* Cabecera */}
          <div className="px-4 py-3 text-white font-bold border-b border-gray-700 flex justify-between items-center">
            Auto Loopin
            <button
              className="text-gray-400 hover:text-white"
              onClick={() => setIsSidebarOpen(false)}
            >
              X
            </button>
          </div>

          {/* Contenido */}
          <div className="px-4 py-2 overflow-y-auto space-y-4">
            {apiConfigs.map((config) => (
              <div
                key={config.apiId}
                className={`border rounded-lg p-3 bg-gray-900 shadow-lg hover:shadow-xl transition-shadow ${
                  selectedApiId === config.apiId
                    ? "border-green-500"
                    : "border-gray-700"
                }`}
              >
                {/* Cabecera */}
                <div className="flex justify-between items-center mb-3">
                  <div className="flex items-center gap-2">
                    <FaRobot size={16} className="text-green-500" />
                    <span className="text-sm font-bold text-white">
                      {config.alias}
                    </span>
                  </div>
                  <div className="flex gap-2">
                    <button
                      className={`p-2 rounded-full ${
                        config.botEnabled ? "bg-green-600" : "bg-gray-700"
                      } hover:bg-green-500`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleToggleBotEnabled(config.apiId, config.botEnabled);
                      }}
                    >
                      <FaPowerOff size={14} className="text-white" />
                    </button>
                    <button
                      className="p-2 rounded-full bg-red-600 hover:bg-red-500 transition"
                      onClick={(e) => {
                        e.stopPropagation();
                        openDeleteConfirmation(config.apiId);
                      }}
                    >
                      <FaTrash size={14} className="text-white" />
                    </button>
                  </div>
                </div>

                {/* Detalles */}
                <div className="grid grid-cols-2 gap-2 text-xs text-gray-300">
                  <div>
                    <p>
                      <span className="font-semibold">Cantidad:</span>{" "}
                      {config.size || "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold">Precio Entrada:</span>{" "}
                      {config.entryPrice ? config.entryPrice.toFixed(2) : "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold">Precio Mercado:</span>{" "}
                      {config.markPrice ? config.markPrice.toFixed(2) : "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold">Precio Liquidación:</span>{" "}
                      {config.liquidationPrice
                        ? config.liquidationPrice.toFixed(2)
                        : "N/A"}
                    </p>
                  </div>
                  <div>
                    <p>
                      <span className="font-semibold">PnL Latente:</span>{" "}
                      <span
                        className={`font-bold ${
                          config.unrealizedPnl < 0
                            ? "text-red-500"
                            : "text-green-500"
                        }`}
                      >
                        {config.unrealizedPnl
                          ? config.unrealizedPnl.toFixed(2)
                          : "N/A"}
                      </span>
                    </p>
                    <p>
                      <span className="font-semibold">Apalancamiento:</span>{" "}
                      {config.leverage
                        ? `${config.leverage.toFixed(1)}x`
                        : "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold">Margen:</span>{" "}
                      {config.margin
                        ? `${config.margin.toFixed(2)} USDT`
                        : "N/A"}
                    </p>
                    <p>
                      <span className="font-semibold">Símbolo:</span>{" "}
                      {config.symbol || "N/A"}
                    </p>
                  </div>
                </div>

                {/* Botón de selección */}
                <div className="mt-3">
                  <button
                    className="w-full px-3 py-2 bg-blue-600 text-xs text-white rounded-md hover:bg-blue-500 transition"
                    onClick={() => handleSelectApiConfig(config.apiId)}
                  >
                    Seleccionar Configuración
                  </button>
                </div>
              </div>
            ))}

            {/* Nueva Configuración */}
            <div
              className="border border-dashed border-green-500 rounded-lg p-3 bg-gray-800 cursor-pointer hover:bg-green-600 transition"
              onClick={() => {
                setIsFormPopupOpen(true);
                setIsSidebarOpen(false);
              }}
            >
              <div className="flex items-center gap-2">
                <FaPlus size={16} className="text-white" />
                <span className="text-sm text-white font-semibold">
                  Nueva Configuración
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Popup Configuración Usuario */}
        {isFormPopupOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 text-white p-6 rounded-lg max-w-lg w-full shadow-xl">
              {/* Título */}
              <h2 className="text-xl font-bold mb-6 text-center">
                Configuración del Usuario
              </h2>

              {/* Formulario */}
              <form className="space-y-4">
                <div>
                  <label className="block text-sm font-medium">Alias:</label>
                  <input
                    type="text"
                    className="w-full mt-1 p-2 bg-gray-700 rounded-md border border-gray-600 focus:ring-2 focus:ring-green-500 focus:outline-none"
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium">API Key:</label>
                  <input
                    type="text"
                    className="w-full mt-1 p-2 bg-gray-700 rounded-md border border-gray-600 focus:ring-2 focus:ring-green-500 focus:outline-none"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium">
                    API Secret:
                  </label>
                  <input
                    type="text"
                    className="w-full mt-1 p-2 bg-gray-700 rounded-md border border-gray-600 focus:ring-2 focus:ring-green-500 focus:outline-none"
                    value={apiSecret}
                    onChange={(e) => setApiSecret(e.target.value)}
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium">
                    API Passphrase:
                  </label>
                  <input
                    type="text"
                    className="w-full mt-1 p-2 bg-gray-700 rounded-md border border-gray-600 focus:ring-2 focus:ring-green-500 focus:outline-none"
                    value={apiPassphrase}
                    onChange={(e) => setApiPassphrase(e.target.value)}
                  />
                </div>

                <div className="flex items-center mt-4">
                  <input
                    type="checkbox"
                    id="botEnabled"
                    checked={botEnabled}
                    onChange={(e) => setBotEnabled(e.target.checked)}
                    className="w-4 h-4 text-green-500 border-gray-300 rounded focus:ring-green-500"
                  />
                  <label
                    htmlFor="botEnabled"
                    className="ml-2 text-sm font-medium"
                  >
                    Activar bot
                  </label>
                </div>

                {/* Botones */}
                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
                    onClick={() => setIsFormPopupOpen(false)}
                  >
                    Cerrar
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-400 transition"
                    onClick={handleSaveUserConfig}
                  >
                    Guardar
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* Popup Configuración Operaciones */}
        {isOpsConfigOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-panelBackground text-textLight p-6 rounded-lg w-full max-w-lg">
              <h2 className="text-lg font-semibold mb-4">
                Configuración de Operaciones
              </h2>
              <p className="text-sm mb-4">
                Para enlazar con TradingView, sigue estos pasos:
              </p>
              <ol className="list-decimal list-inside text-sm mb-4">
                <li>
                  Accede a tu cuenta de TradingView con el ID:{" "}
                  <button
                    onClick={handleCopyToClipboard}
                    className="ml-2 text-gray-500 hover:text-gray-300 focus:outline-none"
                    aria-label="Copiar ID al portapapeles"
                  >
                    <FaCopy size={16} />
                  </button>
                  {copySuccess && (
                    <span className="ml-2 text-green-500 font-medium">
                      ¡Copiado!
                    </span>
                  )}
                </li>
                <li>Genera una clave API desde la sección de integraciones.</li>
                <li>
                  Ingresa la clave API en el campo correspondiente de
                  configuración.
                </li>
              </ol>
              <div className="flex justify-between mt-4">
                <button
                  className="px-4 py-2 rounded-lg bg-danger text-white"
                  onClick={() => setIsOpsConfigOpen(false)}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Popup Confirmacion borrar api */}
        {isDeleteConfirmOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-panelBackground text-textLight p-6 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold mb-4">
                ¿Eliminar Configuración?
              </h3>
              <p className="mb-4">Esta acción no se puede deshacer.</p>
              <div className="flex justify-end gap-4">
                <button
                  className="px-4 py-2 rounded-lg bg-gray-500 text-white hover:bg-gray-400"
                  onClick={() => setIsDeleteConfirmOpen(false)}
                >
                  Cancelar
                </button>
                <button
                  className="px-4 py-2 rounded-lg bg-red-600 text-white hover:bg-red-500"
                  onClick={handleDeleteApiConfig}
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}


export default Operations;
