import React, { useState, useEffect } from "react";
import { fetchPositionsHistory } from "../services/api";
import { useAuth } from "../hooks/useAuth";

const History = () => {
  const { user } = useAuth();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadHistory = async () => {
      try {
        const data = await fetchPositionsHistory(user.id);
        setHistory(data);
      } catch (error) {
        console.error("Error loading history:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user.id) {
      loadHistory();
    }
  }, [user.id]);

  const formatType = (type) => {
    if (type === "CLOSE_LONG") return "Largo";
    if (type === "CLOSE_SHORT") return "Corto";
    return type || "N/A";
  };

  const formatSymbol = (symbol) => {
    if (!symbol) return "N/A";
    return symbol.replace("USDTM", "");
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 98, // Elimina cualquier margen superior con el header
        left: 0,
        width: "100%",
        height: "calc(100% - 98px)", // Resta el alto del header
        backgroundColor: "", // Fondo oscuro
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        overflow: "hidden", // Elimina scroll externo
      }}
    >
      {loading ? (
        <p className="text-center text-gray-400">Cargando...</p>
      ) : history.length > 0 ? (
        <div
          className="w-full h-full overflow-y-auto"
          style={{
            padding: "0", // Elimina márgenes adicionales
            scrollbarWidth: "thin", // Scroll delgado en navegadores modernos
            scrollbarColor: "#4b5563 #1f2937",
          }}
        >
          <style>
            {`
              /* Scroll personalizado */
              ::-webkit-scrollbar {
                width: 8px;
              }
              ::-webkit-scrollbar-thumb {
                background: #4b5563; /* Color oscuro del scroll */
                border-radius: 4px;
              }
              ::-webkit-scrollbar-thumb:hover {
                background: #374151; /* Color al pasar el mouse */
              }
              ::-webkit-scrollbar-track {
                background: #1f2937; /* Fondo del track */
              }
            `}
          </style>
          {history.map((position, index) => (
            <div
              key={index}
              className="w-full bg-panelBackground bg-opacity-95 text-white p-2 border-b flex flex-wrap sm:flex-nowrap items-center sm:items-start last:border-b-0"
              style={{
                width: "100%", // Ocupa todo el ancho
                marginBottom: 0, // Sin márgenes inferiores
              }}
            >
              {/* Contrato y Alias */}
              <div className="w-1/2 sm:w-1/6 flex flex-col justify-center mb-2 sm:mb-0">
                <h4 className="font-semibold text-base sm:text-lg truncate">
                  {formatSymbol(position.symbol)}
                </h4>
                <span className="text-xs sm:text-sm text-gray-400 truncate">
                  {position.alias || "Alias no disponible"}
                </span>
              </div>

              {/* Tipo */}
              <div className="w-1/2 sm:w-1/6 flex justify-center mb-2 sm:mb-0">
                <span
                  className={`px-3 py-1 rounded-full text-xs sm:text-sm font-medium ${
                    position.type === "CLOSE_SHORT"
                      ? "bg-red-500 text-white"
                      : "bg-green-500 text-white"
                  }`}
                >
                  {formatType(position.type)}
                </span>
              </div>

              {/* Pérdidas/Ganancias */}
              <div className="w-1/2 sm:w-1/6 text-center mb-2 sm:mb-0">
                <p className="text-xs sm:text-sm text-gray-400">
                  Pérdidas/Ganancias
                </p>
                <p
                  className={`font-semibold text-sm sm:text-base ${
                    parseFloat(position.pnl) > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {position.pnl
                    ? `${parseFloat(position.pnl).toFixed(2)} USDT`
                    : "N/A"}
                </p>
              </div>
              
              {/* Precios de Apertura y Cierre */}
              <div className="w-1/2 sm:w-1/4 text-center mb-2 sm:mb-0">
                <p className="text-xs sm:text-sm text-gray-400">Precios</p>
                <p className="font-semibold text-xs sm:text-sm text-white">
                  Apertura: {position.openPrice || "N/A"}
                </p>
                <p className="font-semibold text-xs sm:text-sm text-white">
                  Cierre: {position.closePrice || "N/A"}
                </p>
              </div>

              {/* Fecha y Hora */}
              <div className="w-1/2 sm:w-1/4 text-center">
                <p className="text-xs sm:text-sm text-gray-400">Fecha y Hora</p>
                <p className="font-semibold text-xs sm:text-sm">
                  {position.closeTime
                    ? new Date(position.closeTime).toLocaleDateString()
                    : "N/A"}
                </p>
                <p className="font-semibold text-xs sm:text-sm">
                  {position.closeTime
                    ? new Date(position.closeTime).toLocaleTimeString()
                    : "N/A"}
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-400">No hay datos disponibles.</p>
      )}
    </div>
  );
};

export default History;
