import { useState, useEffect, createContext, useContext } from 'react';

// Crear el contexto de autenticación
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Inicializar datos del usuario desde localStorage
        const storedUser = localStorage.getItem('user');
        return storedUser ? JSON.parse(storedUser) : null;
    });

    // Función para iniciar sesión
    const login = (userData) => {
        setUser(userData); // Establece los datos del usuario en el estado
        localStorage.setItem('user', JSON.stringify(userData)); // Guarda en localStorage
    };

    // Función para cerrar sesión
    const logout = () => {
        setUser(null); // Elimina los datos del usuario del estado
        localStorage.removeItem('user'); // Elimina los datos del usuario de localStorage
    };

    // Proveer el estado y las funciones de login/logout
    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook personalizado para usar el contexto de autenticación
export const useAuth = () => useContext(AuthContext);
