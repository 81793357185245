import axios from 'axios';
import { useAuth } from '../hooks/useAuth';

const API_BASE_URL = "https://api.loopin.es";
//const API_BASE_URL = "https://dev.loopin.es";
//const API_BASE_URL = 'http://localhost:8080';

export const openOrder = async (orderData, user) => {
    if (!user) {
        throw new Error('Usuario no autenticado');
    }
    
    try {
        const response = await axios.post(`${API_BASE_URL}/webhook/multiopen`, {
            ...orderData,
            userId: user.id, // Incluye el userId en el payload
        });
        return response.data;
    } catch (error) {
        console.error('Error opening order:', error);
        throw error;
    }
};

export const closeOrder = async (user) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/webhook/multiclose`, {
            userId: user.id, // Incluye el userId en el payload
        });
        return response.data;
    } catch (error) {
        console.error('Error saving user config:', error);
        throw error;
    }
};

export const saveUserConfig = async (configData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/user-configs/update`, configData);
        return response.data;
    } catch (error) {
        console.error('Error saving user config:', error);
        throw error;
    }
};


export const saveOrderConfig = async (orderData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/trading-configs/update`, orderData);
        return response.data;
    } catch (error) {
        console.error('Error saving user config:', error);
        throw error;
    }
};

export const fetchApiConfigs = async (userId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user-configs/all/${userId}`, {
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        console.log("Response:", response);
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error("Error fetching configs:", error.response?.data || error.message);
        return [];
    }
};


export const fetchUserConfigs = async (userId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user-configs/orderconfig/${userId}`, {
            headers: { 
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        });
        console.log("Response:", response);
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error("Error fetching configs:", error.response?.data || error.message);
        return [];
    }
};



export const deleteApiConfig = async (apiId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/user-configs/api/apiconfig/delete/${apiId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting API configuration:", error);
        throw error;
    }
};

export const toggleBotEnabled = async (apiId, enabled) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/user-configs/api/apiconfig/toggle-bot/${apiId}`,
            null,
            { params: { enabled } } // Envía el parámetro enabled
        );
        return response.data; // Devuelve el objeto actualizado desde el backend
    } catch (error) {
        console.error("Error en toggleBotEnabled API:", error);
        throw error;
    }
};

export const fetchOrderConfigs = async (userId) => {
    try {
        const response = await axios.get(
          `${API_BASE_URL}/trading-configs/orderconfig`,
          {
            params: { userId }, // Pasar el parámetro como query param
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        console.log("Response:", response.data);
        return response.data; // Retornar la configuración del usuario
    } catch (error) {
        console.error("Error fetching configs:", error.response?.data || error.message);
        return null; // Retornar null en caso de error
    }
};

export const fetchPositionsHistory = async (userId, daysBack = 7) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/report/positions-history`, {
            params: { userId, daysBack },
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        console.log("Active positions:", response.data);
        return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
        console.error("Error fetching active positions:", error.response?.data || error.message);
        return []; // Retornar un array vacío en caso de error
    }
};

export const fetchDashboardData = async (userId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/v1/dashboard/${userId}`);
        return response.data; // Retorna los datos en formato de lista
    } catch (error) {
        console.error('Error al obtener datos del dashboard:', error);
        throw error;
    }
};

/**
 * Obtiene los detalles de la cuenta de futuros de un usuario.
 * @param {string} userId - ID del usuario.
 * @returns {Array} Detalles de las cuentas de futuros.
 */
export const fetchFuturesAccountDetails = async (userId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/account/futures/${userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    console.log("Detalles de la cuenta de futuros:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener los detalles de la cuenta de futuros:",
      error.response?.data || error.message
    );
    throw error;
  }
};

/**
 * Obtiene el historial de operaciones de un usuario.
 * @param {string} userId - ID del usuario.
 * @param {number} daysBack - Días hacia atrás para consultar.
 * @returns {Array} Historial de operaciones.
 */
export const fetchOperationsSummary = async (userId, daysBack = 7) => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/report/positions-history?userId=${userId}&daysBack=${daysBack}`
    );
    console.log("Historial de operaciones:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Error al obtener el historial de operaciones:",
      error.response?.data || error.message
    );
    throw error;
  }
};

/**
 * Calcula la ganancia o pérdida total a partir del historial.
 * @param {Array} history - Historial de operaciones.
 * @returns {string} Ganancia/Pérdida total formateada a 2 decimales.
 */
export const calculateTotalProfit = (history) => {
  if (!history || history.length === 0) return "0.00";
  return history
    .reduce((sum, item) => sum + parseFloat(item.pnl || 0), 0)
    .toFixed(2);
};

/**
 * Calcula el porcentaje de ganancia o pérdida neta (PNL).
 * @param {Array} history - Historial de operaciones.
 * @returns {string} Porcentaje de PNL formateado a 2 decimales.
 */
export const calculatePercentagePNL = (history) => {
  if (!history || history.length === 0) return "0.00";
  const totalPNL = history.reduce((sum, item) => sum + parseFloat(item.pnl || 0), 0);
  const totalInvested = history.reduce((sum, item) => sum + parseFloat(item.openPrice || 0), 0);
  return totalInvested > 0 ? ((totalPNL / totalInvested) * 100).toFixed(2) : "0.00";
};

export const calculateOperationsByAlias = (history) => {
    if (!history || history.length === 0) return {};
    return history.reduce((counts, item) => {
      counts[item.alias] = (counts[item.alias] || 0) + 1;
      return counts;
    }, {});
  };
  
  export const calculateDailyOperationsByAlias = (history) => {
    if (!history || history.length === 0) return {};
    return history.reduce((counts, item) => {
      const date = new Date(item.closeTime).toLocaleDateString();
      counts[date] = counts[date] || {};
      counts[date][item.alias] = (counts[date][item.alias] || 0) + 1;
      return counts;
    }, {});
  };
/**
 * Calcula el mayor beneficio obtenido en una operación.
 * @param {Array} history - Historial de operaciones.
 * @returns {string} Mayor beneficio formateado a 2 decimales.
 */
export const calculateLargestProfit = (history) => {
  if (!history || history.length === 0) return "0.00";
  return history
    .filter((item) => parseFloat(item.pnl) > 0)
    .reduce((max, item) => Math.max(max, parseFloat(item.pnl || 0)), 0)
    .toFixed(2);
};

/**
 * Calcula la mayor pérdida sufrida en una operación.
 * @param {Array} history - Historial de operaciones.
 * @returns {string} Mayor pérdida formateada a 2 decimales.
 */
export const calculateLargestLoss = (history) => {
  if (!history || history.length === 0) return "0.00";
  return history
    .filter((item) => parseFloat(item.pnl) < 0)
    .reduce((min, item) => Math.min(min, parseFloat(item.pnl || 0)), 0)
    .toFixed(2);
};

/**
 * Calcula las ganancias o pérdidas diarias.
 * @param {Array} history - Historial de operaciones.
 * @returns {Array} Lista con ganancias/pérdidas diarias.
 */
export const calculateDailyPNL = (history) => {
  if (!history || history.length === 0) return [];
  const dailyPNLMap = history.reduce((acc, item) => {
    const date = new Date(item.closeTime).toLocaleDateString();
    acc[date] = (acc[date] || 0) + parseFloat(item.pnl || 0);
    return acc;
  }, {});
  return Object.entries(dailyPNLMap).map(([date, pnl]) => ({
    date,
    pnl: parseFloat(pnl).toFixed(2),
  }));
};

/**
 * Calcula la tasa de operaciones ganadoras.
 * @param {Array} history - Historial de operaciones.
 * @returns {string} Tasa de operaciones ganadoras formateada a 2 decimales.
 */
export const calculateWinningRate = (history) => {
  if (!history || history.length === 0) return "0.00";
  const totalOperations = history.length;
  const winningOperations = history.filter((op) => parseFloat(op.pnl) > 0).length;
  return ((winningOperations / totalOperations) * 100).toFixed(2);
};

/**
 * Calcula el promedio de operaciones realizadas por día.
 * @param {Array} history - Historial de operaciones.
 * @returns {string} Promedio de operaciones por día formateado a 2 decimales.
 */
export const calculateAverageOperationsPerDay = (history) => {
  if (!history || history.length === 0) return "0.00";
  const uniqueDates = new Set(
    history.map((op) => new Date(op.closeTime).toLocaleDateString())
  ).size;
  return (history.length / uniqueDates).toFixed(2);
};

  