import React, { useEffect, useRef } from 'react';

const Market = () => {
    const widgetContainer = useRef(null);

    useEffect(() => {
        if (widgetContainer.current) {
            widgetContainer.current.innerHTML = '';

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-screener.js';
            script.async = true;
            script.innerHTML = JSON.stringify({
                width: "100%",
                height: "100%",
                defaultColumn: "overview",
                screener_type: "crypto_mkt",
                displayCurrency: "USD",
                colorTheme: "dark",
                locale: "es",
            });

            widgetContainer.current.appendChild(script);
        }

        return () => {
            if (widgetContainer.current) widgetContainer.current.innerHTML = '';
        };
    }, []);

    return (
      <div
        style={{
          position: "absolute",
          top: 98,
          left: 0,
          width: "100%",
          height: "calc(100% - 98px)",
          backgroundColor: "#",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          ref={widgetContainer}
          style={{
            width: "100%",
            height: "100%",
          }}
        ></div>
      </div>
    );
};

export default Market;
