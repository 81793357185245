import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();

    const API_BASE_URL = "https://api.loopin.es";
    //const API_BASE_URL = 'http://localhost:8080';
    //const API_BASE_URL = "https://dev.loopin.es";
  
    const handleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {
                const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v1/userinfo?alt=json', {
                    headers: {
                        Authorization: `Bearer ${tokenResponse.access_token}`,
                    },
                });
    
                const userInfo = await userInfoResponse.json();
                const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
                    userId: userInfo.id,
                    email: userInfo.email,
                });
    
                const user = {
                    id: userInfo.id,
                    name: userInfo.name,
                    email: userInfo.email,
                    picture: userInfo.picture,
                    token: tokenResponse.access_token,
                    jwt: response.data.token,
                };
    
                localStorage.setItem('user', JSON.stringify(user));
                login(user);
                navigate('/');
            } catch (error) {
                console.error('Error durante el login o registro:', error);
                if (error.response && error.response.status === 403) {
                    alert('El email no está autorizado. Por favor, contacta con soporte.');
                }
            }
        },
        onError: (error) => {
            console.error('Error durante Google login:', error);
        },
    });
    

    return (
        <div
            className="flex w-full h-screen bg-cover bg-center"
            style={{ backgroundImage: "url('/images/.png')" }}
        >
            <div className="inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
            <div className="rounded-lg bg-Background p-4 flex mx-auto items-center justify-center h-full inset-0">
                <div className="bg-white rounded-2xl shadow-2xl p-8 max-w-lg w-11/12">
                    <div className="text-center">
                        <h1 className="text-4xl font-extrabold text-gray-900">LooPin</h1>
                        <p className="text-gray-600 mt-4 text-sm">
                            Accede a tu cuenta con Google para entrar a LooPin.
                        </p>
                    </div>
                    <div className="mt-6">
                        <button
                            onClick={handleLogin}
                            className="w-full flex items-center justify-center bg-gray-800 text-white font-semibold py-3 rounded-lg hover:bg-gray-700 shadow-lg transition"
                        >
                            <img
                                src="https://developers.google.com/identity/images/g-logo.png"
                                alt="Google Logo"
                                className="h-6 w-6 mr-3"
                            />
                            Inicia sesión con Google
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
