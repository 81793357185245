const Footer = () => {
    return (
      <footer className="w-full text-white bg-black bg-opacity-50 flex items-center justify-center">
        <p className="text-sm sm:text-xs flex flex-wrap justify-center xs:text-xs">
          © {new Date().getFullYear()} Loopin. Todos los derechos reservados.
          <span className="ml-2">Desarrollado por <span className="text-green-400">Loopin Team</span>.</span>
        </p>
      </footer>
    );
  };
  
  export default Footer;
  